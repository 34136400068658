import { apiHttp } from '../axiosApi'
import {
  productCategoryByFacility,
  productCategoryPath,
} from '../config/apiRoute'

export const getProductsCategoriesByFacility = async facilityId => apiHttp('GET', `${productCategoryByFacility}${facilityId}`)
export const getProductCategory = async id => apiHttp('GET', `${productCategoryPath}${id}`)
export const postProductCategory = async productCategoryObject => apiHttp('POST', `${productCategoryPath}`, productCategoryObject)
export const putProductCategory = async productCategoryObject => apiHttp('PUT', `${productCategoryPath}${productCategoryObject.id}`, productCategoryObject)
export const deleteProductCategory = async id => apiHttp('DELETE', `${productCategoryPath}${id}`)
