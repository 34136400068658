import { apiHttp } from '../axiosApi'
import { metrics, metricspicks, metricsinsights } from '../config/apiRoute'

/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable import/prefer-default-export */
/* eslint-disable arrow-body-style */

export const getMetrics = async (facilityId, month, year) => apiHttp('GET', `${metrics}${facilityId}/${month}/${year}`)

export const getAllPicks = async filters => apiHttp('GET', `${metricspicks}?filter=${JSON.stringify(filters)}`)

export const getInsights = async (groupId, facilityId, startDate, endDate) => apiHttp('GET', `${metricsinsights}${groupId}/${facilityId}/${startDate}/${endDate}`)
