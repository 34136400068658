import { apiHttp } from '../axiosApi'
// eslint-disable-next-line object-curly-newline
import { cameraAll, cameraAllActive, cameraPath, cameraThumbnails, cameraStatus, cameraFacility } from '../config/apiRoute'

export const getCameras = async () => apiHttp('GET', `${cameraAll}`)
export const getCamerasByFacility = async facilityId => apiHttp('GET', `${cameraFacility}${facilityId}`)
export const getCamerasActive = async () => apiHttp('GET', `${cameraAllActive}`)
export const getCamerasById = async id => apiHttp('GET', `${cameraPath}${id}`)
export const getCamerasThumbnails = async id => apiHttp('GET', `${cameraThumbnails}${id}`)
export const createCamera = async body => apiHttp('POST', `${cameraPath}`, body)
export const editCamera = async (body, id) => apiHttp('PUT', `${cameraPath}${id}`, body)
export const putCameraStatus = async id => apiHttp('PUT', `${cameraStatus}${id}`)
export const deleteCamera = async id => apiHttp('DELETE', `${cameraPath}${id}`)
