/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable import/prefer-default-export */
import { apiHttp } from '../axiosApi'
import {
  terminalInitiate,
  terminalSimCardTap,
  terminalCapture,
  terminalPaymentIntent,
  terminalPath,
  terminalReaders,
  terminalFacility,
  terminalFacilityActive,
} from '../config/apiRoute'

export const initiatePaymentTerminal = async terminalId => apiHttp('POST', `${terminalInitiate}${terminalId}`)
export const SimCardPaymentTerminal = async () => apiHttp('GET', `${terminalSimCardTap}`)
export const capturePaymentTerminal = async piId => apiHttp('PUT', `${terminalCapture}${piId}`)
export const paymentIntentTerminal = async () => apiHttp('GET', `${terminalPaymentIntent}`)
export const getTerminals = async () => apiHttp('GET', `${terminalPath}`)
export const getTerminal = async id => apiHttp('GET', `${terminalPath}${id}`)
export const getTerminalReaders = async facilityId => apiHttp('GET', `${terminalReaders}${facilityId}`)
export const getTerminalFacility = async facilityId => apiHttp('GET', `${terminalFacility}${facilityId}`)
export const getTerminalFacilityActive = async facilityId => apiHttp('GET', `${terminalFacilityActive}${facilityId}`)
export const createTerminal = async body => apiHttp('POST', `${terminalPath}`, body)
export const editTerminal = async (body, id) => apiHttp('PUT', `${terminalPath}${id}`, body)
export const deleteTerminal = async id => apiHttp('DELETE', `${terminalPath}${id}`)
