import { apiHttp } from '../axiosApi'

// eslint-disable-next-line object-curly-newline
import {
  facilityAll,
  facilityAllActive,
  facilityPath,
  facilityUpdateLogo,
  facilityByGroup,
  facilityByGroupMin,
  facilityUpdateImage,
  facilityDeleteImage,
  facilityTimezones,
  facilityConnectStatus,
  facilityConnect,
  facilityConnectLink,
} from '../config/apiRoute'

const { accessToken } = localStorage

export const getFacilities = async () => apiHttp('GET', `${facilityAll}`)
export const getFacilitiesActive = async () => apiHttp('GET', `${facilityAllActive}`)
export const getFacilityById = async facilityId => apiHttp('GET', `${facilityPath}${facilityId}`)
export const getFacilitiesByGroup = async groupId => apiHttp('GET', `${facilityByGroup}${groupId}`)
export const getFacilitiesByGroupMin = async groupId => apiHttp('GET', `${facilityByGroupMin}${groupId}`)
export const getFacilityTimezones = async () => apiHttp('GET', `${facilityTimezones}`)
export const createFacility = async body => apiHttp('POST', `${facilityPath}`, body)
export const editFacility = async (body, facilityId) => apiHttp('PUT', `${facilityPath}${facilityId}`, body)
export const editFacilityLogo = async (body, facilityId) => apiHttp('PUT', `${facilityUpdateLogo}${facilityId}`, body, {
  'Content-Type': 'multipart/form-data',
  'el-token': `Bearer ${accessToken}`,
  Accept: 'application/json',
  'Access-Control-Allow-Origin': '*',
})
export const editFacilityImage = async (body, facilityId) => apiHttp('PUT', `${facilityUpdateImage}${facilityId}`, body, {
  'Content-Type': 'multipart/form-data',
  'el-token': `Bearer ${accessToken}`,
  Accept: 'application/json',
  'Access-Control-Allow-Origin': '*',
})
export const deleteFacility = async id => apiHttp('DELETE', `${facilityPath}${id}`)
export const deleteFacilityImage = async (body, id) => apiHttp('DELETE', `${facilityDeleteImage}${id}`, body)
export const getFacilityConnectStatus = async facilityId => apiHttp('GET', `${facilityConnectStatus}${facilityId}`)
export const putFacilityConnect = async (body, facilityId) => apiHttp('PUT', `${facilityConnect}${facilityId}`, body)
export const getFacilityConnectLink = async facilityId => apiHttp('GET', `${facilityConnectLink}${facilityId}`)
