/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable import/prefer-default-export */
import { apiHttp } from '../axiosApi'
import {
  onSitePaymentsByFacility,
  onSitePaymentsActiveByFacility,
  onsitePaymentMethodPath,
} from '../config/apiRoute'

export const getOnSitePaymentMethodsByFacility = async facilityId => apiHttp('GET', `${onSitePaymentsByFacility}${facilityId}`)
export const getOnSitePaymentMethodsActiveByFacility = async facilityId => apiHttp('GET', `${onSitePaymentsActiveByFacility}${facilityId}`)
export const getOnsitePaymentMethod = async id => apiHttp('GET', `${onsitePaymentMethodPath}${id}`)
export const postOnsitePaymentMethod = async onsitePaymentMethodObject => apiHttp('POST', `${onsitePaymentMethodPath}`, onsitePaymentMethodObject)
export const putOnsitePaymentMethod = async onsitePaymentMethodObject => apiHttp('PUT', `${onsitePaymentMethodPath}${onsitePaymentMethodObject.id}`, onsitePaymentMethodObject)
export const deleteOnsitePaymentMethod = async id => apiHttp('DELETE', `${onsitePaymentMethodPath}${id}`)
