/* eslint-disable implicit-arrow-linebreak */
import { apiHttp } from '../axiosApi'
import {
  contactPath,
  contactByFacility,
  contactCategoryPath,
  contactCategoryByFacility,
  contactCategoryActiveByFacility,
} from '../config/apiRoute'

export const getContactFacility = async facilityId => apiHttp('GET', `${contactByFacility}${facilityId}`)
export const getContactFacilitySearch = async (facilityId, search) => apiHttp('GET', `${contactByFacility}${facilityId}/${search}`)
export const getContact = async id => apiHttp('GET', `${contactPath}${id}`)
export const createContact = async body => apiHttp('POST', `${contactPath}`, body)
export const editContact = async (body, id) => apiHttp('PUT', `${contactPath}${id}`, body)
export const deleteContact = async id => apiHttp('DELETE', `${contactPath}${id}`)

export const getContactCategoryFacility = async facilityId => apiHttp('GET', `${contactCategoryByFacility}${facilityId}`)
export const getContactCategoryActiveFacility = async facilityId => apiHttp('GET', `${contactCategoryActiveByFacility}${facilityId}`)
export const getContactCategory = async id => apiHttp('GET', `${contactCategoryPath}${id}`)
export const createContactCategory = async body => apiHttp('POST', `${contactCategoryPath}`, body)
export const editContactCategory = async (body, id) => apiHttp('PUT', `${contactCategoryPath}${id}`, body)
export const deleteContactCategory = async id => apiHttp('DELETE', `${contactCategoryPath}${id}`)
