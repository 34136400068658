/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
import { apiHttp } from '../axiosApi'
import {
  chuckFieldPath,
  chuckOrderVideo,
  chuckMinutesOrderVideo,
  chuckMinutesAll,
  chunksActivityControl,
} from '../config/apiRoute'

export const getChucksByField = async id => apiHttp('GET', `${chuckFieldPath}${id}`)
export const getChucksOrderVideo = async (fieldId, date) => apiHttp('GET', `${chuckOrderVideo}${fieldId}/${date}`)
export const getChucksMinutesAll = async minutes => apiHttp('GET', `${chuckMinutesAll}${minutes}`)
export const getChucksMinutesOrderVideo = async (fieldId, date, minutes) =>
  apiHttp('GET', `${chuckMinutesOrderVideo}${fieldId}/${date}/${minutes}`)
export const getChunksActivityControl = async (fieldId, date, startTime, minutes) =>
  apiHttp('GET', `${chunksActivityControl}${fieldId}/${date}/${startTime}/${minutes}`)
