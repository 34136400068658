<template>
  <div v-show="!(menuIsVerticalNavMini && !isMouseHovered)">
    <v-list-item
      v-if="userData.role === 'A'"
      class="vertical-nav-menu-link"
      active-class="bg-gradient-primary"
    >
      <v-list-item-title class="pt-2">
        <v-select
          v-model="configOrganization"
          :label="t('organizations.organization')"
          :placeholder="t('organizations.organization')"
          :items="organizationsOptions"
          item-text="name"
          item-value="id"
          outlined
          dense
          hide-details
        ></v-select>
      </v-list-item-title>
    </v-list-item>

    <v-list-item
      class="vertical-nav-menu-link"
      active-class="bg-gradient-primary"
    >
      <v-list-item-title class="pt-2">
        <v-select
          v-model="configFacility"
          :label="t('facilities.facility')"
          :placeholder="t('facilities.facility')"
          :items="facilitiesOptions"
          item-text="name"
          item-value="id"
          outlined
          dense
          hide-details
          :disabled="!configOrganization"
          :append-icon="facilitiesOptions.length === 1 || !configOrganization ? null : icons.mdiMenuDown"
          :readonly="facilitiesOptions.length === 1 || !configOrganization"
        ></v-select>
      </v-list-item-title>
    </v-list-item>
  </div>
</template>

<script>
import { useUtils } from '@core/libs/i18n'
import { ref, inject } from '@vue/composition-api'
import { mdiMenuDown } from '@mdi/js'
import useAppConfig from '@core/@app-config/useAppConfig'
import useSelectOptions from '@core/utils/useSelectOptions'

export default {
  setup() {
    const { t } = useUtils()
    const { menuIsVerticalNavMini } = useAppConfig()
    const isMouseHovered = inject('isMouseHovered')
    const {
      userData,
      organizationsOptions,
      facilitiesOptions,
      configOrganization,
      configFacility,
    } = useSelectOptions(true)

    const organization = ref(null)
    const facility = ref(null)

    return {
      userData,
      organization,
      facility,
      organizationsOptions,
      facilitiesOptions,
      configOrganization,
      configFacility,

      menuIsVerticalNavMini,
      isMouseHovered,

      // i18n
      t,

      // Icons
      icons: {
        mdiMenuDown,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';
</style>
