/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable import/prefer-default-export */

import { apiHttp } from '../axiosApi'

import {
  userAppAll,
  userAppPath,
  userAppSave,
  userAppUpdateAvatar,
  userAppPaged,
} from '../config/apiRoute'

const { accessToken } = localStorage

export const getUsersApp = async () => apiHttp('GET', `${userAppAll}`)
export const getUsersAppPaged = async (filter, order, count, page) =>
  apiHttp('GET', `${userAppPaged}?filter=${filter}&order=${order}&count=${count}&page=${page}`)
export const createUserApp = async body => apiHttp('POST', `${userAppSave}`, body)
export const editUserApp = async (body, userId) => apiHttp('PUT', `${userAppPath}${userId}`, body)

// export const getUsersAppActive = async () => apiHttp('GET', `${userAppAllActive}`)
export const editUserAppAvatar = async (body, userId) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  apiHttp('PUT', `${userAppUpdateAvatar}${userId}`, body, {
    'Content-Type': 'multipart/form-data',
    'el-token': `Bearer ${accessToken}`,
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
  })
