import { apiHttp } from '../axiosApi'
// eslint-disable-next-line object-curly-newline
import {
  fieldAll,
  fieldByFacility,
  fieldAllActive,
  fieldPath,
  fieldApp,
  fieldNoCamera,
  fieldUpdateImage,
  fieldDeleteImage,
  fieldAllByFacility,
} from '../config/apiRoute'

const { accessToken } = localStorage

export const getFields = async () => apiHttp('GET', `${fieldAll}`)
export const getFieldsActive = async () => apiHttp('GET', `${fieldAllActive}`)
export const getFieldsAllByFacility = async id => apiHttp('GET', `${fieldAllByFacility}${id}`)
export const getFieldsByFacility = async id => apiHttp('GET', `${fieldByFacility}${id}`)
export const getFieldsById = async id => apiHttp('GET', `${fieldPath}${id}`)
export const createField = async body => apiHttp('POST', `${fieldPath}`, body)
export const editField = async (body, id) => apiHttp('PUT', `${fieldPath}${id}`, body)
export const deleteField = async id => apiHttp('DELETE', `${fieldPath}${id}`)
export const getFieldApp = async id => apiHttp('GET', `${fieldApp}${id}`)
export const getFieldNoCamera = async id => apiHttp('GET', `${fieldNoCamera}${id}`)
export const editFieldImage = async (body, fieldId) => apiHttp('PUT', `${fieldUpdateImage}${fieldId}`, body, {
  'Content-Type': 'multipart/form-data',
  'el-token': `Bearer ${accessToken}`,
  Accept: 'application/json',
  'Access-Control-Allow-Origin': '*',
})
export const deleteFieldImage = async (body, id) => apiHttp('DELETE', `${fieldDeleteImage}${id}`, body)
