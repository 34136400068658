import { apiHttp } from '../axiosApi'

import { rankingGroupPath } from '../config/apiRoute'

export const getRankingGroups = async () => apiHttp('GET', `${rankingGroupPath}all/`)
export const getRankingGroupsActive = async () => apiHttp('GET', `${rankingGroupPath}active/`)
export const getRankingGroup = async id => apiHttp('GET', `${rankingGroupPath}${id}`)
export const createRankingGroup = async body => apiHttp('POST', `${rankingGroupPath}`, body)
export const editRankingGroup = async (body, id) => apiHttp('PUT', `${rankingGroupPath}${id}`, body)
export const deleteRankingGroup = async id => apiHttp('DELETE', `${rankingGroupPath}${id}`)
