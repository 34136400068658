import { apiHttp } from '../axiosApi'
import { blockedTimePath, blockedTimeFacility, recurringEventPath } from '../config/apiRoute'

export const getBlockedTimeByFacility = async (facilityId, type) => apiHttp('GET', `${blockedTimeFacility}${facilityId} ${type ? `?type=${type}` : ''}`)
export const getBlockedTime = async id => apiHttp('GET', `${blockedTimePath}${id}`)
export const createBlockedTime = async body => apiHttp('POST', `${blockedTimePath}`, body)
export const editBlockedTime = async (body, id) => apiHttp('PUT', `${blockedTimePath}${id}`, body)
export const deleteBlockedTime = async id => apiHttp('DELETE', `${blockedTimePath}${id}`)

export const createRecurringEvent = async body => apiHttp('POST', `${recurringEventPath}`, body)
