import { apiHttp } from '../axiosApi'

// eslint-disable-next-line object-curly-newline
import { sportAll, sportAllActive, sportPath, sportSave } from '../config/apiRoute'

export const getSports = async () => apiHttp('GET', `${sportAll}`)
export const getSportsActive = () => apiHttp('GET', `${sportAllActive}`)
export const getSport = async sportId => apiHttp('GET', `${sportPath}${sportId}`)
export const createSport = async body => apiHttp('POST', `${sportSave}`, body)
export const editSport = async (body, sportId) => apiHttp('PUT', `${sportPath}${sportId}`, body)
export const deleteSport = async id => apiHttp('DELETE', `${sportPath}${id}`)
