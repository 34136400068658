import { apiHttp } from '../axiosApi'
import {
  productPath,
  productImage,
  productSummary,
  productFacility,
} from '../config/apiRoute'

const { accessToken } = localStorage

export const getProductsByFacility = async facilityId => apiHttp('GET', `${productFacility}${facilityId}`)
export const getProductSummary = async (facilityId, productCategoryId, search) => apiHttp('GET', `${productSummary}${facilityId}/${productCategoryId}/${search}`)
export const getProduct = async id => apiHttp('GET', `${productPath}${id}`)
export const createProduct = async body => apiHttp('POST', `${productPath}`, body)
export const editProduct = async (body, id) => apiHttp('PUT', `${productPath}${id}`, body)
export const editProductImage = async (body, id) => apiHttp('PUT', `${productImage}${id}`, body, {
  'Content-Type': 'multipart/form-data',
  'el-token': `Bearer ${accessToken}`,
  Accept: 'application/json',
  'Access-Control-Allow-Origin': '*',
})
export const deleteProduct = async id => apiHttp('DELETE', `${productPath}${id}`)
