/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable import/prefer-default-export */
import { apiHttp } from '../axiosApi'
import {
  transactionPath,
  transactionAll,
  transactionsByFacility,
  transactionPaged,
  transactionsGroupSummary,
  transactionsMonthly,
} from '../config/apiRoute'

export const getTransactions = async () => apiHttp('GET', `${transactionAll}`)
export const getTransaction = async id => apiHttp('GET', `${transactionPath}${id}`)
export const getTransactionsPaged = async (filter, order, count, page) =>
  apiHttp('GET', `${transactionPaged}?filter=${filter}&order=${order}&count=${count}&page=${page}`)
export const getTransactionsByFacility = async facilityId => apiHttp('GET', `${transactionsByFacility}${facilityId}`)
export const getTransactionsGroupSummary = async groupId => apiHttp('GET', `${transactionsGroupSummary}${groupId}`)
export const getTransactionsMonthly = async (groupId, year, month) => apiHttp('GET', `${transactionsMonthly}${groupId}/${year}/${month}`)
