import { apiHttp } from '../axiosApi'
import {
  inventoryPath,
  inventoryEditPath,
  createInventoryPath,
} from '../config/apiRoute'

export const getInventory = async facilityId => apiHttp('GET', `${inventoryPath}${facilityId}`)
export const getEditInventory = async facilityId => apiHttp('GET', `${inventoryEditPath}${facilityId}`)
export const getInventaryAdjust = async id => apiHttp('GET', `${createInventoryPath}${id}`)
export const createInventaryAdjust = async object => apiHttp('POST', `${createInventoryPath}`, object)
