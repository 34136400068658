import { apiHttp } from '../axiosApi'
import {
  playersGroupAll,
  playersGroupPath,
  playersGroupByGroup,
  playersGroupByFacility,
  playersGroupLogo,
  playersGroupLogoSearch,
} from '../config/apiRoute'

const { accessToken } = localStorage

export const getPlayersGroups = async () => apiHttp('GET', `${playersGroupAll}`)
export const getPlayersGroupsByGroup = async groupId => apiHttp('GET', `${playersGroupByGroup}${groupId}`)
export const getPlayersGroupsByFacility = async facilityId => apiHttp('GET', `${playersGroupByFacility}${facilityId}`)
export const getPlayersGroupsByFacilitySearch = async (facilityId, search) => apiHttp('GET', `${playersGroupByFacility}${facilityId}/${search}`)
export const getPlayersGroupsSearch = async phone => apiHttp('GET', `${playersGroupLogoSearch}${phone}`)
export const getPlayersGroup = async id => apiHttp('GET', `${playersGroupPath}${id}`)
export const createPlayersGroup = async body => apiHttp('POST', `${playersGroupPath}`, body)
export const editPlayersGroup = async (body, id) => apiHttp('PUT', `${playersGroupPath}${id}`, body)
export const editPlayersGroupLogo = async (body, id) => apiHttp('PUT', `${playersGroupLogo}${id}`, body, {
  'Content-Type': 'multipart/form-data',
  'el-token': `Bearer ${accessToken}`,
  Accept: 'application/json',
  'Access-Control-Allow-Origin': '*',
})
export const deletePlayersGroup = async id => apiHttp('DELETE', `${playersGroupPath}${id}`)
