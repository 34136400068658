/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable import/prefer-default-export */
import { apiHttp } from '../axiosApi'
import {
  paymentPath,
  paymentAll,
  paymentsByFacility,
  paymentsByGroup,
  paymentPaged,
  paymentUserAppPaged,
  paymentUserAppPath,
} from '../config/apiRoute'

export const createPayment = async body => apiHttp('POST', `${paymentPath}`, body)
export const getPayments = async () => apiHttp('GET', `${paymentAll}`)
export const getPaymentsPaged = async (filter, order, count, page) =>
  apiHttp('GET', `${paymentPaged}?filter=${filter}&order=${order}&count=${count}&page=${page}`)
export const getPaymentsUserAppPaged = async (filter, order, count, page) =>
  apiHttp('GET', `${paymentUserAppPaged}?filter=${filter}&order=${order}&count=${count}&page=${page}`)
export const getPaymentUserApp = async id => apiHttp('GET', `${paymentUserAppPath}${id}`)
export const getPaymentById = async id => apiHttp('GET', `${paymentPath}${id}`)
export const getPaymentsByFacility = async facilityId => apiHttp('GET', `${paymentsByFacility}${facilityId}`)
export const getPaymentsByGroup = async groupId => apiHttp('GET', `${paymentsByGroup}${groupId}`)
