/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable import/prefer-default-export */
import { apiHttp } from '../axiosApi'
import {
  recurringOrderAll,
  recurringOrderGroup,
  recurringOrderPath,
  recurringOrderPaged,
  recurringOrderVideos,
} from '../config/apiRoute'

export const getRecurringOrders = async () => apiHttp('GET', `${recurringOrderAll}`)
export const getRecurringOrdersPaged = async (filter, order, count, page) =>
  apiHttp('GET', `${recurringOrderPaged}?filter=${filter}&order=${order}&count=${count}&page=${page}`)
export const getRecurringOrdersByGroup = async groupId => apiHttp('GET', `${recurringOrderGroup}${groupId}`)
export const createRecurringOrder = async body => apiHttp('POST', `${recurringOrderPath}`, body)
export const createRecurringOrderVideo = async body => apiHttp('POST', `${recurringOrderVideos}`, body)
export const getRecurringOrder = async id => apiHttp('GET', `${recurringOrderPath}${id}`)
export const editRecurringOrder = async (body, id) => apiHttp('PUT', `${recurringOrderPath}${id}`, body)
export const deleteRecurringOrder = async id => apiHttp('DELETE', `${recurringOrderPath}${id}`)
