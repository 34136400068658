/* eslint-disable implicit-arrow-linebreak */
import { apiHttp } from '../axiosApi'
import {
  videoOrder,
  videoPlayback,
  videoPath,
  videoAll,
  videosByFacility,
  videosPreorder,
  videosPreorderGroup,
  videoTransactionEdit,
  videoTransactionCancel,
  orderVideoImport,
  videoPaged,
  videoOrderEdit,
  videoUserViews,
} from '../config/apiRoute'

// import { facilityAll, userSave, userLogin, userWeb, deleteUser, userWebReset } from '../config/apiRoute'

export const getVideos = async () => apiHttp('GET', `${videoAll}`)
export const getVideo = async id => apiHttp('GET', `${videoPath}${id}`)
export const getVideosByFacility = async facilityId => apiHttp('GET', `${videosByFacility}${facilityId}`)
export const getVideoUserViews = async id => apiHttp('GET', `${videoUserViews}${id}`)
export const createOrderVideo = async body => apiHttp('POST', `${videoOrder}`, body)
export const editOrderVideo = async (body, id) => apiHttp('PUT', `${videoTransactionEdit}${id}`, body)
export const getOrderVideoStartTimes = async id => apiHttp('GET', `${videoOrderEdit}${id}`)
export const getVideoPlayback = async ref => apiHttp('GET', `${videoPlayback}${ref}`)
export const cancelVideoTransaction = async (id, data) => apiHttp('PUT', `${videoTransactionCancel}${id}`, data)
export const getVideoPreorder = async (groupId, fieldId, videoDate, startTime, endTime, videoType) =>
  apiHttp('GET', `${videosPreorder}${groupId}/${fieldId}/${videoDate}/${startTime}/${endTime}/${videoType}`)
export const getVideoPreorderGroup = async (groupId, videoDate, startTime, endTime) =>
  apiHttp('GET', `${videosPreorderGroup}${groupId}/${videoDate}/${startTime}/${endTime}`)
export const getVideoImport = async (date, mac) => apiHttp('GET', `${orderVideoImport}${mac}/${date}`)
export const getVideosPaged = async (filter, order, count, page) =>
  apiHttp('GET', `${videoPaged}?filter=${filter}&order=${order}&count=${count}&page=${page}`)

export const getVideoPlaybackType = async (ref, videoType, username) => apiHttp('GET', `${videoPlayback}${ref}/${videoType}/${username}`, {}, {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})
