import {
  ref,
  computed,
  watch,
  onMounted,
} from '@vue/composition-api'
import {
  getGroupsActive,
  getFacilitiesByGroupMin,
} from '@api'
import useCryptoJs from '@core/utils/useCryptoJs'
import store from '@/store'

export default function useSelectOptions(configFetch = false) {
  const { userData } = useCryptoJs()

  const timerFetch = ref(null)
  const organizationsOptions = ref([])
  const facilitiesOptions = ref([])

  const configOrganization = computed({
    get: () => store.state.app.configOrganization,
    set: value => {
      store.commit('app/UPDATE_ORGANIZATION', value || 0)
      localStorage.setItem('config-organization', value || 0)
    },
  })

  const configFacility = computed({
    get: () => store.state.app.configFacility,
    set: value => {
      store.commit('app/UPDATE_FACILITY', value || 0)
      localStorage.setItem('config-facility', value || 0)
    },
  })

  const fetchFacilitiesByOrganization = async organizationId => {
    let resp = null
    let facilities = []
    if (organizationId) {
      resp = await getFacilitiesByGroupMin(organizationId)
    } else if (userData.value && userData.value.role !== 'A') {
      resp = await getFacilitiesByGroupMin(userData.value.group_id)
    } else if (configOrganization.value) {
      resp = await getFacilitiesByGroupMin(configOrganization.value)
    }

    if (resp && resp.ok) facilities = resp.data

    if (userData.value.facilities_ids.length) facilitiesOptions.value = facilities.filter(e => userData.value.facilities_ids.includes(e.id))
    else facilitiesOptions.value = facilities

    if (facilitiesOptions.value.length && !configFacility.value) configFacility.value = facilitiesOptions.value[0].id
  }

  const debounceFetch = async (time = 1000) => {
    clearTimeout(timerFetch.value)
    timerFetch.value = setTimeout(async () => {
      await fetchFacilitiesByOrganization(configOrganization.value)
    }, time)
  }

  const fetchOrganizations = async () => {
    const resp = await getGroupsActive()
    if (resp.ok) organizationsOptions.value = resp.data
    if (organizationsOptions.value.length && !configOrganization.value) configOrganization.value = organizationsOptions.value[0].id
  }

  watch([configOrganization], async () => {
    if (configFetch) {
      facilitiesOptions.value = []
      configFacility.value = 0
      await debounceFetch(300)
    }
  })

  onMounted(async () => {
    if (configFetch) {
      if (userData.value && userData.value.role !== 'A') {
        configOrganization.value = userData.value.group_id
        await debounceFetch(300)
      } else if (organizationsOptions.value.length === 0) {
        await fetchOrganizations()
        await debounceFetch(500)
      }
    }
  })

  return {
    userData,

    // options
    organizationsOptions,
    facilitiesOptions,

    // computed
    configOrganization,
    configFacility,

    fetchOrganizations,
    fetchFacilitiesByOrganization,
  }
}
