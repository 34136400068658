import { apiHttp } from '../axiosApi'
// eslint-disable-next-line object-curly-newline
import {
  paymentMethodPath,
  paymentMethodAll,
  paymentMethodAllMin,
  paymentMethodFacility,
  paymentMethodGroup,
  paymentMethodGroupMin,
  paymentMethodActivate,
  paymentMethodInactivate,
  paymentMethodDefault,
} from '../config/apiRoute'

export const postPaymentMethod = async paymentMethod => apiHttp('POST', `${paymentMethodPath}`, paymentMethod)
export const putPaymentMethod = async (paymentMethod, id) => apiHttp('PUT', `${paymentMethodPath}${id}`, paymentMethod)
export const putActivatePaymentMethod = async id => apiHttp('PUT', `${paymentMethodActivate}${id}`)
export const putInactivatePaymentMethod = async id => apiHttp('PUT', `${paymentMethodInactivate}${id}`)
export const putDefaultPaymentMethod = async id => apiHttp('PUT', `${paymentMethodDefault}${id}`)
export const getPaymentMethods = async () => apiHttp('GET', `${paymentMethodAll}`)
export const getPaymentMethodsMin = async () => apiHttp('GET', `${paymentMethodAllMin}`)
export const getPaymentMethodsByFacility = async id => apiHttp('GET', `${paymentMethodFacility}${id}`)
export const getPaymentMethodsByGroup = async id => apiHttp('GET', `${paymentMethodGroup}${id}`)
export const getPaymentMethodsByGroupMin = async id => apiHttp('GET', `${paymentMethodGroupMin}${id}`)
export const deletePaymentMethods = async id => apiHttp('DELETE', `${paymentMethodPath}${id}`)
