/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable import/prefer-default-export */
import { apiHttp } from '../axiosApi'
// eslint-disable-next-line object-curly-newline
import { highlightAll, highlightPaged, highlightPath } from '../config/apiRoute'

export const getHighlights = async () => apiHttp('GET', `${highlightAll}`)
export const getHighlight = async id => apiHttp('GET', `${highlightPath}${id}`)
export const getHighlightsPaged = async (filter, order, count, page) =>
  apiHttp('GET', `${highlightPaged}?filter=${filter}&order=${order}&count=${count}&page=${page}`)
