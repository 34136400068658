/* eslint-disable implicit-arrow-linebreak */
import { apiHttp } from '../axiosApi'
import {
  invoicePos,
  invoiceOpenTab,
  invoiceOpenTabs,
  invoiceInitiatePayment,
  invoicePosPaymentDeclined,
  invoicePaymentPaged,
  invoicePosOpenTab,
  invoicePaymentPath,
} from '../config/apiRoute'

export const createInvoicePos = async body => apiHttp('POST', `${invoicePos}`, body)
export const getInvoicePos = async invoiceId => apiHttp('GET', `${invoicePosOpenTab}${invoiceId}`)
export const updateInvoicePos = async (body, invoiceId) => apiHttp('PUT', `${invoicePosOpenTab}${invoiceId}`, body)
export const createInvoicePosDeclined = async body => apiHttp('POST', `${invoicePosPaymentDeclined}`, body)
export const getInvoiceOpenTabs = async facilityId => apiHttp('GET', `${invoiceOpenTabs}${facilityId}`)
export const deleteInvoiceOpenTab = async invoiceId => apiHttp('DELETE', `${invoiceOpenTab}${invoiceId}`)
export const getInvoicePayment = async id => apiHttp('GET', `${invoicePaymentPath}${id}`)
export const getInvoicePaymentsPaged = async (filter, order, count, page) =>
  apiHttp('GET', `${invoicePaymentPaged}?filter=${filter}&order=${order}&count=${count}&page=${page}`)
export const initiatePaymentInvoice = async amount => apiHttp('GET', `${invoiceInitiatePayment}${amount}`)
