/* eslint-disable no-param-reassign */
/* eslint-disable arrow-body-style */
// eslint-disable-next-line object-curly-newline
import {
  mdiAccountMultipleOutline,
  mdiAccountGroupOutline,
  mdiCurrencyUsd,
  mdiVideoOutline,
  mdiFileDocumentOutline,
  mdiSoccerField,
  mdiHomeGroup,
  mdiCamera,
  mdiCreditCardMultipleOutline,
  mdiVideoPlusOutline,
  mdiBell,
  mdiAccountKeyOutline,
  mdiContentDuplicate,
  mdiViewDashboard,
  mdiMonitorMultiple,
  mdiCalendarMonth,
  mdiCashRegister,
  mdiStoreOutline,
  mdiOfficeBuildingOutline,
  mdiFaceAgent,
  mdiClipboardTextSearchOutline,
  mdiChartTimelineVariant,
  mdiForumOutline,
  mdiPointOfSale,
  mdiChatOutline,
  mdiCellphone,
  mdiSoccer,
  mdiListStatus,
  mdiFileVideoOutline,
  mdiBellOutline,
  mdiPackageVariantClosed,
  mdiPackage,
  mdiCreditCardOutline,
  mdiCalendarCheckOutline,
  mdiFileCancel,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import useCryptoJs from '@/@core/utils/useCryptoJs'

export default function usePermissions() {
  const { userData } = useCryptoJs()
  const PERMISSIONS = ref([
    {
      id: 1,
      title: 'Order Videos',
      icon: mdiVideoPlusOutline,
    },
    {
      id: 2,
      title: 'Order History',
      icon: mdiFileDocumentOutline,
    },
    {
      id: 3,
      title: 'Payment History',
      icon: mdiCurrencyUsd,
    },
    {
      id: 4,
      title: 'Payment Methods',
      icon: mdiCreditCardMultipleOutline,
    },
    {
      id: 5,
      title: 'Recordings',
      icon: mdiVideoOutline,
    },
    {
      id: 16,
      title: 'Roles',
      icon: mdiAccountKeyOutline,
    },
    {
      id: 6,
      title: 'Console Users',
      icon: mdiAccountMultipleOutline,
    },
    {
      id: 7,
      title: 'App Users',
      icon: mdiCellphone,
    },
    {
      id: 8,
      title: 'Cameras',
      icon: mdiCamera,
    },
    {
      id: 15,
      title: 'Organizations',
      icon: mdiOfficeBuildingOutline,
    },
    {
      id: 9,
      title: 'Facilities',
      icon: mdiHomeGroup,
    },
    {
      id: 10,
      title: 'Fields',
      icon: mdiSoccerField,
    },
    {
      id: 11,
      title: 'Sports',
      icon: mdiSoccer,
    },
    {
      id: 12,
      title: 'Activity',
      icon: mdiListStatus,
    },
    {
      id: 13,
      title: 'Highlights',
      icon: mdiFileVideoOutline,
    },
    {
      id: 14,
      title: 'Notifications',
      icon: mdiBellOutline,
    },
    {
      id: 17,
      title: 'Support',
      icon: mdiFaceAgent,
    },
    {
      id: 37,
      title: 'phone_support',
      icon: mdiChatOutline,
    },
    {
      id: 18,
      title: 'Updates',
      icon: mdiBell,
    },
    {
      id: 19,
      title: 'Recurring Orders',
      icon: mdiContentDuplicate,
    },
    {
      id: 20,
      title: 'Dashboard',
      icon: mdiViewDashboard,
    },
    {
      id: 21,
      title: 'Field Monitoring',
      icon: mdiMonitorMultiple,
    },
    {
      id: 22,
      title: 'Fanaty Payments',
      icon: mdiCurrencyUsd,
    },
    {
      id: 23,
      title: 'Schedule',
      icon: mdiCalendarMonth,
    },
    {
      id: 24,
      title: 'Facility Payments',
      icon: mdiCurrencyUsd,
    },
    {
      id: 25,
      title: 'Groups',
      icon: mdiAccountGroupOutline,
    },
    {
      id: 26,
      title: 'POS',
      icon: mdiCashRegister,
    },
    {
      id: 27,
      title: 'Products',
      icon: mdiPackageVariantClosed,
    },
    {
      id: 28,
      title: 'Products Categories',
      icon: mdiPackage,
    },
    {
      id: 29,
      title: 'Inventory Adjustment',
      icon: mdiStoreOutline,
    },
    {
      id: 30,
      title: 'Onsite Payments Methods',
      icon: mdiCreditCardOutline,
    },
    {
      id: 31,
      title: 'Bookings',
      icon: mdiClipboardTextSearchOutline,
    },
    {
      id: 32,
      title: 'Metrics',
      icon: mdiChartTimelineVariant,
    },
    {
      id: 33,
      title: 'Communications',
      icon: mdiForumOutline,
    },
    {
      id: 34,
      title: 'Terminals',
      icon: mdiPointOfSale,
    },
    {
      id: 35,
      title: 'Recurring Bookings',
      icon: mdiCalendarCheckOutline,
    },
    {
      id: 36,
      title: 'Contacts',
      icon: mdiAccountMultipleOutline,
    },
    {
      id: 38,
      title: 'Blocked Times',
      icon: mdiAccountMultipleOutline,
    },
    {
      id: 39,
      title: 'Cancellation Reasons',
      icon: mdiFileCancel,
    },
    {
      id: 40,
      title: 'Ranking Group',
      icon: mdiFileCancel,
    },
  ])

  const getPermissionsGroup = permissions => {
    const groupPermissions = permissions.reduce((groups, item) => {
      const group = (groups[item.view_id] || [])
      group.push(item)
      groups[item.view_id] = group

      return groups
    }, {})

    const mapPermissions = PERMISSIONS.value.map(e => {
      return {
        ...e,
        permissions: groupPermissions[e.id],
      }
    })

    return mapPermissions
  }

  const hasPermission = id => userData.value.permissions_ids.includes(id)

  const hasActionPermission = (idShow, idEdit, idDelete) => {
    return userData.value.permissions_ids.includes(idShow)
    || userData.value.permissions_ids.includes(idEdit)
    || userData.value.permissions_ids.includes(idDelete)
  }

  return {
    PERMISSIONS,
    userData,

    getPermissionsGroup,
    hasPermission,
    hasActionPermission,
  }
}
